function getConfig() {
  const defaultConfig = {
    apiUrl: 'https://propfix-backend-go-mm4ahu6lbq-uc.a.run.app',
    supabaseUrl: 'https://tcgmonunzroeujvmqcir.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRjZ21vbnVuenJvZXVqdm1xY2lyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg1MDE3MjcsImV4cCI6MjAwNDA3NzcyN30.9T3t17tRoGzWIM612xzJ5-eElWjEYePZIfwtvioX95w'
  };

  const location = window.location.href;
  
  if (location.includes('localhost')) {
    return defaultConfig;
  } 
  return defaultConfig;

}

const config = getConfig();

export default config;
